<template>
    <div class="partners">
        <v-text-field
            label="Kereső"
            single-line
            outline
            v-model="searchText"
        ></v-text-field>
        <v-toolbar flat color="white">
        <v-toolbar-title><i class="fas fa-user-tie"></i>Foglakoztató cégek</v-toolbar-title>
        <v-divider
            class="mx-2"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px" attach="#app">
            <v-btn slot="activator" color="primary" dark class="mb-2">Új foglalkoztató</v-btn>
            <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12>
                        <v-text-field v-model="editedItem.p_tax_id" label="Adószám"></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field v-model="editedItem.p_name" label="Cégnév"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-text-field v-model="editedItem.p_comission" type="number" label="Szöv. jutalék(%)"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-text-field v-model="editedItem.p_max_comission" type="number" label="Szöv. max jutalék(Ft)"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-select v-model="editedItem.p_invoice_type" :items="invoiceTypes" label="Számla kiküldés"></v-select>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-select v-model="editedItem.p_vat_type" :items="vatTypes" label="ÁFA mód"></v-select>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex>
                        <v-switch
                            :label="'Számla tömbösítve'"
                            v-model="editedItem.p_batch_invoice"
                        ></v-switch>
                    </v-flex>
                </v-layout>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="close">Bezár</v-btn>
                <v-btn color="blue darken-1" flat @click.native="save">Mentés</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :items="partners"
            :loading="loading"
            :rows-per-page-text="'Foglalkoztató/Oldal'" 
            :rows-per-page-items="rowsPerPage"       
            :search="searchText"   
            class="elevation-1"
        >
            <v-progress-linear slot="progress" color="#faa540" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
            <td>{{ props.item.p_tax_id }}</td>
            <td class="text-xs-left">{{ props.item.p_name }}</td>
            <td class="text-xs-right">{{ props.item.p_comission }}</td>
            <td class="text-xs-right">{{ props.item.p_max_comission }}</td>
            <td class="text-xs-right">{{ props.item.p_invoice_type }}</td>
            <td class="text-xs-right">{{ props.item.p_vat_type }}</td>
            <td class="text-xs-right">{{ props.item.createdAt | toLocaleString }}</td>
            <td class="text-xs-right">{{ props.item.updatedAt | toLocaleString }}</td>
            <td class="justify-center layout px-0">
                <span class="crud-action" @click="editItem(props.item)"><i class="fas fa-edit"></i>Módosít</span>
                <span class="crud-action" @click="deleteItem(props.item)"><i class="fas fa-trash-alt"></i>Töröl</span>
            </td>
            </template>
            <template slot="no-data">                
                <div style="text-align: center;">Nincs megjelenítendő adat.</div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import axios from 'axios';
export default {

    data () {
      return {
        loading: false,
        dialog: false,
        rowsPerPage: [10,25,50,{"text":"Mind","value":-1}],
        searchText: '',
        invoiceTypes: ['E-mail','Posta'],
        vatTypes: ['Fordított','Tárgyi mentes','Egyenes áfa','Áfa körön kívüli'],
        headers: [
          {
            text: 'Adószám',
            align: 'left',
            sortable: false,
            value: 'p_tax_id'
          },
          { text: 'Cégnév', value: 'p_name', width: '300px', align: 'left' },
          { text: 'Szöv. jutalék (%)', value: 'p_comission' },
          { text: 'Szöv. max jutalék', value: 'p_max_comission' },
          { text: 'Számla kiküldés', value: 'p_invoice_type' },
          { text: 'ÁFA mód', value: 'p_vat_type' },
          { text: 'Bejegyezve', value: 'createdAt', width: '210px' },
          { text: 'Utoljára módosítva', value: 'updatedAt', width: '210px' },
          { text: 'Szerkesztés', sortable: false}
        ],
        partners: [],
        editedIndex: -1,
        editedItem: {
            p_tax_id: '',
            p_name: '',
            p_comission: 17.8,
            p_max_comission: 25000,
            p_invoice_type: 'E-mail',
            p_vat_type: 'Fordított'
        },
        defaultItem: {
            p_tax_id: '',
            p_name: '',
            p_comission: 17.8,
            p_max_comission: 25000,
            p_invoice_type: 'E-mail',
            p_vat_type: 'Fordított'
        }
      }
    },
    mounted() {
        this.loading = true;
        axios.get('/partners?_limit=10000')
        .then(res => {
            this.partners = res.data;
            this.loading = false;
        })
        .catch(error => {
            console.log(error);
            this.loading = false;
        })
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Új Foglalkoztató' : 'Foglalkoztató szerkesztése'
        }
    },
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    methods: {
        editItem (item) {
            this.editedIndex = this.partners.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            const index = this.partners.indexOf(item)
            var answer = confirm('Biztosan törlöd a foglalkoztatót?');
            if (answer === true) {
                axios.delete('/partners/' + this.partners[index].id)
                .then(res => {
                    this.partners.splice(index, 1);
                    this.$store.dispatch('showSnackBar',{text: 'Foglalkoztató törölve.', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Foglalkoztató törlése sikertelen.', color: 'error'});
                })
            }            
        },
        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },
        save () {

            //Check Tax ID format
            var taxIDsclies = this.editedItem.p_tax_id.split('-');
            if (taxIDsclies.length !== 3 || taxIDsclies[0].length !== 8 || taxIDsclies[1].length !==1 || taxIDsclies[2].length !== 2) {
                this.$store.dispatch('showSnackBar',{text: 'Adószám formátuma nem megfelelő!', color: 'error'});
                return;
            }

            if (this.editedIndex > -1) {
                axios.put('/partners/'+this.editedItem.id,{
                    p_tax_id: this.editedItem.p_tax_id,
                    p_name: this.editedItem.p_name,
                    p_comission: parseFloat(this.editedItem.p_comission),
                    p_max_comission: parseInt(this.editedItem.p_max_comission),
                    p_invoice_type: this.editedItem.p_invoice_type,
                    p_vat_type: this.editedItem.p_vat_type,
                    p_batch_invoice: this.editedItem.p_batch_invoice
                })
                .then(res => {
                    Object.assign(this.partners[this.editedIndex], res.data);
                    this.$store.dispatch('showSnackBar',{text: 'Foglalkoztató adatok módosítva.', color: 'success'});
                    this.close();
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Módosítás sikertelen.', color: 'error'});
                })
            } else {
                axios.post('/partners',{
                    p_tax_id: this.editedItem.p_tax_id,
                    p_name: this.editedItem.p_name,
                    p_comission: parseFloat(this.editedItem.p_comission),
                    p_max_comission: parseInt(this.editedItem.p_max_comission),
                    p_invoice_type: this.editedItem.p_invoice_type,
                    p_vat_type: this.editedItem.p_vat_type,
                    p_batch_invoice: this.editedItem.p_batch_invoice
                })
                .then(res => {
                    this.partners.push(res.data);
                    this.$store.dispatch('showSnackBar',{text: 'Új foglalkoztató felvéve.', color: 'success'});
                    this.close();
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Új foglalkoztató felvétele sikertelen.', color: 'error'});
                })
            }            
            
        }
    }
    
}
</script>
<style lang="scss" scoped>
    .partners {
        padding: 20px;
        width: 100%;
    }
</style>
