import axios from 'axios';

const state = {
    jwt: '',
    user: {},
    username: 'xy',
    isAuthenticated: false
};

const getters = {
    jwt: state => {
        return state.jwt;
    },
    isAuthenticated: state => {
        return state.isAuthenticated
    },
    username: state => {
        return state.username;
    }
};

const mutations = {
    authUser(state, userData){
        state.jwt = userData.jwt;
        state.user = userData.user;
        state.username = userData.user.username;
        state.isAuthenticated = userData.isAuthenticated;
    }
};

const actions = {
    login({commit}, authData){

        window.localStorage.setItem('jwt',authData.jwt);
        window.localStorage.setItem('user',JSON.stringify(authData.user))

        commit('authUser',{
            jwt: authData.jwt,
            user: authData.user,
            isAuthenticated: true
        })
        axios.defaults.headers.common['Authorization'] = 'Bearer '+ authData.jwt;
    },
    logout({commit}){

        window.localStorage.removeItem('jwt');
        window.localStorage.removeItem('user');

        commit('authUser',{
            jwt: '',
            user: {},
            isAuthenticated: false
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
