<template>
    <div class="contracts">
        <v-layout wrap justify-space-between><v-flex xs12 sm6>
            <v-text-field
                label="Kereső"
                single-line
                outline
                v-model="searchText"
            ></v-text-field>
        </v-flex>
        <v-flex xs2>
            <v-switch
                :label="'Csak aktívak'"
                v-model="onlyActives"
                @change="fetchContracts"
            ></v-switch>
        </v-flex></v-layout>
        <v-toolbar flat color="white">
        <v-toolbar-title><i class="fas fa-handshake"></i>Munkaszerződések</v-toolbar-title>
        <v-divider
            class="mx-2"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px" attach="#app">
            <v-btn slot="activator" color="primary" dark class="mb-2">Új munkaszerződés</v-btn>
            <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs10>
                        <v-text-field v-model="editedItem.c_job_title" label="Munka"></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                        <v-switch
                            :label="`Aktív: ${editedItem.c_active ? 'Igen':'Nem'}`"
                            v-model="editedItem.c_active"
                        ></v-switch>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-combobox v-model="editedItem.c_partner.id" item-value="id" item-text="id_name" :items="partners" label="Válasszon foglalkoztatót"></v-combobox>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-combobox v-model="editedItem.c_member.id"  item-value="id" item-text="id_name" :items="members" label="Válasszon dolgozót"></v-combobox>
                    </v-flex>
                    <v-flex xs12 sm4>
                        <v-dialog
                            ref="start_date_dialog"
                            v-model="startDateModal"
                            :return-value.sync="editedItem.c_start_date"
                            persistent
                            lazy
                            full-width
                            width="290px"
                        >
                            <v-text-field
                            slot="activator"
                            v-model="editedItem.c_start_date"
                            label="Munka kezdete"
                            readonly
                            ></v-text-field>
                            <v-date-picker v-model="editedItem.c_start_date" scrollable first-day-of-week="1">
                            <v-spacer></v-spacer>
                            <v-btn flat color="primary" @click="startDateModal = false">Bezár</v-btn>
                            <v-btn flat color="primary" @click="$refs.start_date_dialog.save(editedItem.c_start_date)">OK</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-flex>
                    <v-flex xs12 sm4>
                        <v-dialog
                            ref="end_date_dialog"
                            v-model="endDateModal"
                            :return-value.sync="editedItem.c_end_date"
                            persistent
                            lazy
                            full-width
                            width="290px"
                            v-if="!editedItem.c_indefinite_period"
                        >
                            <v-text-field
                            slot="activator"
                            v-model="editedItem.c_end_date"
                            label="Munka vége"
                            readonly
                            ></v-text-field>
                            <v-date-picker v-model="editedItem.c_end_date" scrollable first-day-of-week="1">
                            <v-spacer></v-spacer>
                            <v-btn flat color="primary" @click="endDateModal = false">Bezár</v-btn>
                            <v-btn flat color="primary" @click="$refs.end_date_dialog.save(editedItem.c_end_date)">OK</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-flex>
                    <v-flex xs12 sm4>
                        <v-switch
                            :label="`Határozatlan idejű: ${editedItem.c_indefinite_period ? 'Igen':'Nem'}`"
                            v-model="editedItem.c_indefinite_period"
                            ></v-switch>
                    </v-flex>
                    <v-flex xs6 sm3>
                        <v-text-field type="number" v-model="editedItem.c_wage" :min="minHourlyWage" label="Alapdíj"></v-text-field>
                    </v-flex>
                    <v-flex xs6 sm3>
                        <v-select v-model="editedItem.c_wage_unit" :items="wageUnits" label="Mértékegység"></v-select>
                    </v-flex>
                    <v-flex xs6 sm3>
                        <v-select v-if="editedItem.c_wage_unit === 'ft/hó'" v-model="editedItem.c_wage_condition" :items="wageConditions" label="Feltétel"></v-select>
                    </v-flex>
                    <v-flex xs6 sm3>
                        <v-text-field v-if="editedItem.c_wage_unit === 'ft/hó'" v-model="editedItem.c_wage_condition_hours" type="number" label="Feltétel egység (óra)"></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-textarea
                            name="input-7-1"
                            label="Megjegyzés"
                            v-model="editedItem.c_comment"
                        ></v-textarea>
                    </v-flex>
                </v-layout>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="close">Bezár</v-btn>
                <v-btn color="blue darken-1" flat @click.native="save">Mentés</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :items="contracts"
            :loading="loading"
            :rows-per-page-text="'Szerződés/Oldal'" 
            :rows-per-page-items="rowsPerPage"       
            :search="searchText"   
            :custom-filter="customFilter"
            class="elevation-1"
        >
            <v-progress-linear slot="progress" color="#faa540" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
            <td>{{ props.item.c_active ? 'Igen' : 'Nem'}}</td>
            <td>{{ props.item.c_job_title }}</td>
            <td class="text-xs-left">{{ props.item.c_partner.p_name }}</td>
            <td class="text-xs-right">{{ props.item.c_member.m_name }}</td>
            <td class="text-xs-right">{{ props.item.c_start_date | toLocaleDateString }}</td>
            <td class="text-xs-right">{{ props.item.c_indefinite_period ? 'Határozatlan' : $options.filters.toLocaleDateString(props.item.c_end_date) }}</td>
            <td class="text-xs-right">{{ props.item.c_wage }}</td>
            <td class="text-xs-right">{{ props.item.c_comment }}</td>
            <td class="text-xs-right">{{ props.item.updatedAt | toLocaleString }}</td>
            <td class="justify-center layout px-0">
                <span class="crud-action" @click="editItem(props.item)"><i class="fas fa-edit"></i>Módosít</span>
                <span class="crud-action" @click="deleteItem(props.item)"><i class="fas fa-trash-alt"></i>Töröl</span>
            </td>
            </template>
            <template slot="no-data">                
                <div style="text-align: center;">Nincs megjelenítendő adat.</div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import axios from 'axios';
export default {

    data () {
      return {
        loading: false,
        dialog: false,
        rowsPerPage: [10,25,50,{"text":"Mind","value":-1}],
        searchText: '',
        invoiceTypes: ['E-mail','Posta'],
        vatTypes: ['Fordított','Tárgyi mentes','Egyenes áfa','Áfa körön kívüli'],
        wageUnits: ['ft/óra','ft/hó'],
        wageConditions: ['havi','heti','napi','egyszeri'],
        partners: [],
        partner: {},
        member: {},
        members: [],
        startDateModal: false,
        endDateModal: false,
        onlyActives: true,
        minHourlyWage: 857,
        headers: [
            { text: 'Aktív', value: 'c_active'},
            { text: 'Munka', align: 'left', sortable: false, value: 'c_job_title' },
            { text: 'Foglalkoztató', value: 'c_partner', width: '200px', align: 'left' },
            { text: 'Dolgozó', value: 'c_member' },
            { text: 'Munka kezdete', value: 'c_start_date' },
            { text: 'Munka vége', value: 'c_end_date' },
            { text: 'Alapdíj', value: 'c_wage'},
            { text: 'Megjegyzés', value: 'c_comment'},
            { text: 'Utoljára módosítva', value: 'updatedAt' },
            { text: 'Szerkesztés', sortable: false}
        ],
        contracts: [],
        editedIndex: -1,
        editedItem: {
            c_job_title: '',
            c_partner: {},
            c_member: {},
            c_start_date: null,
            c_end_date: null,
            c_indefinite_period: true,
            c_wage: 857,
            c_wage_unit: 'ft/óra',
            c_wage_condition: 'havi',
            c_wage_condition_hours: 0,
            c_comment: '',
            c_active: true
        },
        defaultItem: {
            c_job_title: '',
            c_partner: {},
            c_member: {},
            c_start_date: null,
            c_end_date: null,
            c_indefinite_period: true,
            c_wage: 857,
            c_wage_unit: 'ft/óra',
            c_wage_condition: 'havi',
            c_wage_condition_hours: 0,
            c_comment: '',
            c_active: true
        }
      }
    },
    mounted() {
        this.fetchContracts();
        axios.get('/members?_limit=10000')
        .then(res => {
            this.members = res.data;
            this.members = this.members.map(cur => {
                cur.id_name = cur.m_tax_id + ' - ' + cur.m_name;
                return cur;
            })
        })
        .catch(error => {
            console.log(error);
        })
        axios.get('/partners?_limit=10000')
        .then(res => {
            this.partners = res.data;
            this.partners = this.partners.map(cur => {
                cur.id_name = cur.p_tax_id + ' - ' + cur.p_name;
                return cur;
            })
        })
        .catch(error => {
            console.log(error);
        })
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Új szerződés' : 'Szerződés szerkesztése'
        }
    },
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    methods: {
        editItem (item) {
            this.editedIndex = this.contracts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.editedItem.c_start_date = this.editedItem.c_start_date.split('T')[0];
            this.editedItem.c_end_date ? this.editedItem.c_end_date = this.editedItem.c_end_date.split('T')[0] : '';
            this.dialog = true
        },
        deleteItem (item) {
            const index = this.contracts.indexOf(item)
            var answer = confirm('Biztosan törlöd a szerződést?');
            if (answer === true) {
                axios.delete('/contracts/' + this.contracts[index].id)
                .then(() => {
                    this.contracts.splice(index, 1);
                    this.$store.dispatch('showSnackBar',{text: 'Szerződés törölve.', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Szerződés törlése sikertelen.', color: 'error'});
                })
            }            
        },
        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },
        save () {

            // Check if the wage is below the minimum wage
            let hourly_wage = 0;
            if(this.editedIndex.c_wage_unit === 'ft/óra') {
                hourly_wage = this.editedItem.c_wage
            } else {
                switch (this.editedItem.c_wage_condition) {
                    case "havi":
                    hourly_wage = this.editedItem.c_wage / this.editedItem.c_wage_condition_hours
                    break;
                    case "heti":
                    hourly_wage = (this.editedItem.c_wage / (4 * this.editedItem.c_wage_condition_hours))
                    break;
                    case "napi":
                    hourly_wage = (this.editedItem.c_wage / (21 * this.editedItem.c_wage_condition_hours))
                    break;
                    case "egyszeri":
                    hourly_wage = this.editedItem.c_wage
                    break;
                    default:
                    hourly_wage = this.editedItem.c_wage
                    break;
                }
            }

            if (hourly_wage<this.minHourlyWage) {
                this.$store.dispatch('showSnackBar',{text: 'A bér nem lehet kisebb ' + this.minHourlyWage + ' Ft/óránál!', color: 'error'});
                return;
            }

            if (this.editedIndex > -1) {
                axios.put('/contracts/'+this.editedItem.id,{
                    c_job_title: this.editedItem.c_job_title,
                    c_partner: this.editedItem.c_partner.id.id,
                    c_member: this.editedItem.c_member.id.id,
                    c_start_date: this.editedItem.c_start_date,
                    c_end_date: this.editedItem.c_end_date,
                    c_indefinite_period: this.editedItem.c_indefinite_period,
                    c_wage: parseFloat(this.editedItem.c_wage),
                    c_wage_unit: this.editedItem.c_wage_unit,
                    c_wage_condition: this.editedItem.c_wage_condition,
                    c_wage_condition_hours: parseInt(this.editedItem.c_wage_condition_hours),
                    c_comment: this.editedItem.c_comment,
                    c_active: this.editedItem.c_active
                })
                .then(res => {
                    Object.assign(this.contracts[this.editedIndex], res.data);
                    this.$store.dispatch('showSnackBar',{text: 'Szerződés adatok módosítva.', color: 'success'});
                    this.close();
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Módosítás sikertelen.', color: 'error'});
                })
            } else {
                axios.post('/contracts',{
                    c_job_title: this.editedItem.c_job_title,
                    c_partner: this.editedItem.c_partner.id.id,
                    c_member: this.editedItem.c_member.id.id,
                    c_start_date: this.editedItem.c_start_date,
                    c_end_date: this.editedItem.c_end_date,
                    c_indefinite_period: this.editedItem.c_indefinite_period,
                    c_wage: parseFloat(this.editedItem.c_wage),
                    c_wage_unit: this.editedItem.c_wage_unit,
                    c_wage_condition: this.editedItem.c_wage_condition,
                    c_wage_condition_hours: parseInt(this.editedItem.c_wage_condition_hours),
                    c_comment: this.editedItem.c_comment,
                    c_active: this.editedItem.c_active
                })
                .then(res => {
                    this.contracts.push(res.data);
                    this.$store.dispatch('showSnackBar',{text: 'Új szerződés felvéve.', color: 'success'});
                    this.close();
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Új szerződés felvétele sikertelen.', color: 'error'});
                })
            }            
            
        },
        customFilter(items,search,filter){
            search = search.toString().toLowerCase()
            return items.filter(row => {
                return  filter(row.c_member["m_name"], search) || filter(row.c_partner["p_name"], search);
            });
        },
        fetchContracts() {
            this.loading = true;
            var query = this.onlyActives ? '/contracts?c_active=true&_limit=10000' : '/contracts?_limit=10000'
            axios.get(query)
            .then(res => {
                this.contracts = res.data.map(cur => {
                    if(!cur.c_member) cur.c_member = {}
                    if(!cur.c_partner) cur.c_partner = {}
                    return cur
                });
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
            })
        }
    }    
}
</script>
<style lang="scss" scoped>
    .contracts {
        padding: 20px;
        width: 100%;
    }
</style>
