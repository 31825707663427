/* eslint-disable */
<template>
    <div class="invoices">
        <v-layout wrap justify-space-between>
            <v-flex xs12 sm3>
                <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    :return-value.sync="selectedMonth"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                >
                    <v-text-field
                    slot="activator"
                    v-model="selectedMonth"
                    label="Hónap választó"
                    readonly
                    ></v-text-field>
                    <v-date-picker
                    v-model="selectedMonth"
                    type="month"
                    no-title
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="menu = false">Mégse</v-btn>
                    <v-btn flat color="primary" @click="$refs.menu.save(selectedMonth); getInvoices();">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-flex xs12 sm3>
                <v-switch
                v-model="statusFilter"
                :label="'Ki nem fizetett számlák'"
                ></v-switch>
            </v-flex>
            <v-flex xs12 sm3>
                <v-text-field
                    label="Kereső"
                    single-line
                    outline
                    v-model="searchText"
                ></v-text-field>
            </v-flex>
        </v-layout>
        <v-toolbar flat color="white">
        <v-toolbar-title><i class="fas fa-file-invoice-dollar"></i>Havi számlázáshoz</v-toolbar-title>
        <v-divider
            class="mx-2"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :items="invoices"
            :loading="loading"
            item-key="contract"
            :rows-per-page-text="'Számla/Oldal'" 
            :rows-per-page-items="rowsPerPage"       
            :search="searchText"   
            class="elevation-1"
            ref="invoicesTable"
            :custom-filter="filterStatus"
        >
            <v-progress-linear slot="progress" color="#faa540" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
                <tr @click="toggleRow(props)">
                    <td class="text-xs-left">{{ props.item.p_name }}</td>
                    <td class="text-xs-left">{{ props.item.p_vat_type }}</td>
                    <td class="text-xs-left">{{ props.item.p_invoice_type }}</td>
                    <td class="text-xs-center i-center">
                         <v-edit-dialog
                            :return-value.sync="props.item.invoices.i_id"
                            large
                            lazy
                            persistent
                            @save="saveInvoice(props.item)"
                        >
                            <div>{{ props.item.invoices.i_id }}</div>
                            <div slot="input" class="mt-3 title">{{ props.item.invoices.i_id_id ? 'Módosít' : 'Kitölt'}}</div>
                            <v-text-field
                            slot="input"
                            v-model="props.item.invoices.i_id"
                            label="Számla száma"
                            autofocus
                            ></v-text-field>
                        </v-edit-dialog>
                    </td>
                    <td class="text-xs-right i-right">
                        <v-edit-dialog
                            :return-value.sync="props.item.invoices.i_status"
                            large
                            lazy
                            persistent
                            @save="saveInvoice(props.item)"
                        >
                            <div>{{ getStatusText(props.item.invoices.i_status) }}</div>
                            <div slot="input" class="mt-3 title">{{ props.item.p_name}} - Számla</div>
                                <v-select
                                slot="input"
                                :items="invoiceStatuses"
                                item-text="text"
                                item-value="value"
                                label="Állapot"
                                v-model="props.item.invoices.i_status"
                                ></v-select>
                        </v-edit-dialog>
                    </td>
                </tr>
            </template>
            <template slot="no-data">                
                <div style="text-align: center;">Nincs megjelenítendő adat.</div>
            </template>
            <template slot="expand" slot-scope="props">
                <div style="padding: 20px; background-color: #eaeaea;">
                    <v-data-table
                    :headers="detailsHeader"
                    :items="invoiceDetails"
                    class="elevation-1"
                    hide-actions
                    >
                        <v-progress-linear slot="progress" color="#faa540" indeterminate></v-progress-linear>
                        <template slot="items" slot-scope="props">
                            <tr @click="editInvoiceItem(props.item)">
                                <td class="text-xs-left">{{ props.item.member.m_name }}</td>
                                <td class="text-xs-center">{{ props.item.contract.c_wage | toLocal }}</td>
                                <td class="text-xs-left">{{ props.item.contract.c_wage_unit }}</td>
                                <td class="text-xs-left">{{ props.item.h_hours }}</td>
                                <td class="text-xs-left">{{ props.item.grossWage | toLocal }}</td>
                                <td class="text-xs-left">{{ props.item.serviceFee | toLocal }}</td>
                                <td class="text-xs-left">{{ props.item.netUnit | toLocal }}</td>
                                <td class="text-xs-left">{{ props.item.netWage | toLocal }}</td>
                                <td class="text-xs-left">{{ props.item.calculated ? 'Kalkulált' : 'Jóváhagyott' }}</td>
                            </tr>
                        </template>
                            <template slot="footer">
                                <td :colspan="detailsHeader.length - 1">
                                    <strong>Számla összeg: </strong>
                                </td>
                                <td>
                                    <strong>{{invoiceTotal | toLocal}} Ft</strong>
                                </td>
                            </template>
                        <template slot="no-data">                
                            <div style="text-align: center;">Nincs megjelenítendő adat.</div>
                        </template>
                    </v-data-table>
                </div>
                
            </template>
        </v-data-table>
        <v-dialog v-model="itemDialog" max-width="600">
            <div v-if="itemDialog" style="background-color: #fff;">
               <invoice-item :item="editItem"  @closed="itemDialog = false" @update="listInvoiceDetails(editItem.partner)"></invoice-item>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import InvoiceItem from '@/components/views/InvoiceItem.vue'
export default {
    components: {
        InvoiceItem
    },
    data () {
        return {
            menu: false,
            selectedMonth: '',
            invoices: [],
            loading: false,
            rowsPerPage: [25,50,100,{"text":"Mind","value":-1}],
            searchText: '',
            headers: [
                { text: 'Mukáltató', value: 'p_name', width: '300px', align: 'left' },
                { text: 'ÁFA mód', value: 'p_vat_type', width: '300px', align: 'left' },
                { text: 'Számlázás módja', value: 'p_invoice_type', align: 'left' },
                { text: 'Számla szám', value: 'invoices.i_id', width: '300px', align: 'center' },
                { text: 'Számla állapot', value: 'invoices.i_status', width: '300px', align: 'right' },
            ],
            invoiceStatuses: [
                {text: 'Nem számlázott', value: 0},
                {text: 'Számlázva', value: 1},
                {text: 'Elküldött', value: 2},
                {text: 'Kifizetett', value: 3},
            ],
            invoiceStatusesValues: [],
            invoiceDetails: [],
            detailsHeader: [
                { text: 'Dolgozó', value: 'member.m_name', width: '300px', align: 'left' },
                { text: 'Alapdíj', value: 'member.contract.c_wage', align: 'center' },
                { text: 'Mértékegység', value: 'member.contract.c_wage_unit', align: 'left' },
                { text: 'Óraszám', value: 'h_hours', align: 'left' },
                { text: 'Bruttó kereset', value: 'grossWage', align: 'left' },
                { text: 'Szövetkezeti jutalék', value: 'serviceFee', align: 'left' },
                { text: 'Nettó egység', value: 'netUnit', align: 'left' },
                { text: 'Nettó össz.', value: 'netWage', align: 'left' },
                { text: 'Kalkulált', value: 'calculated', align: 'left' },

            ],
            invoiceTotal: 0,
            editItem: {},
            itemDialog: false,
            statusFilter: false
        }
    },
    methods: {
        getInvoices() {
            window.localStorage.setItem('invoices-month', this.selectedMonth)
            axios.get('/list-invoices?date=' + this.selectedMonth)
            .then(res => {
                this.invoices = res.data
                this.invoices.map(cur => {
                    if(!cur.invoices) {
                        cur.invoices = { i_status: 0}
                    }
                    return cur
                })

                for (let i = 0; i < this.invoices.length; i += 1) {
                    const item = this.invoices[i];
                    this.$set(this.$refs.invoicesTable.expanded, item._id, false);
                }

            })
            .catch(error => {
                console.log(error);
            })
        },
        getStatusText(value) {
            var i = this.invoiceStatusesValues.indexOf(value);
            if(i>=0) {
                return this.invoiceStatuses[i].text
            } else {
                return 'Nem számlázott'
            }
            
        },
        saveInvoice(item) {
            if(item.invoices._id) { // Update
                axios.put('/invoices/' + item.invoices._id,{
                    i_id: item.invoices.i_id,
                    i_status: item.invoices.i_status,
                })
                .then(res => {
                    this.getInvoices();
                    this.$store.dispatch('showSnackBar',{text: 'Számla módosítva!', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Módosítás sikertelen!', color: 'error'});
                })
            } else { // Create
                axios.post('/invoices',{
                    i_period: this.selectedMonth,
                    i_id: item.invoices.i_id,
                    i_status: item.invoices.i_status == 0 ? 1 : item.invoices.i_status,
                    partner: item._id,
                    contracts: item.p_batch_invoice ? null : item.contract
                })
                .then(res => {
                    this.getInvoices();
                    this.$store.dispatch('showSnackBar',{text: 'Számla mentve!', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Számla mentés sikertelen!', color: 'error'});
                })
            }
        },
        toggleRow(props) {
            if(props.expanded) {
                props.expanded = false
            } else {
                this.listInvoiceDetails(props.item)
                props.expanded = true
            }

        },
        listInvoiceDetails(item) {
            let params = ''
            if(item.p_batch_invoice === false) {
                params = 'date=' + this.selectedMonth + '&partner=' + item._id + '&contract=' + item.contract
            } else {
                params = 'date=' + this.selectedMonth + '&partner=' + item._id
            }
            axios.get('/list-invoice-details?' + params)
            .then(res => {
                this.invoiceDetails = res.data
                this.invoiceTotal = 0
                this.invoiceDetails.forEach(item => {
                    this.invoiceTotal += item.netWage
                })
            })
            .catch(error => {
                console.log(error);
            })
        },
        editInvoiceItem(item) {
            this.editItem = item;
            this.itemDialog = true;
        },
        filterStatus(items, search, filter) {
            search = search.toString().toLowerCase()
            if(this.statusFilter) {
                return items.filter(cur => {
                    return cur.invoices.i_status !== 3 && cur.p_name.toString().toLowerCase().includes(search)
                })
            } else {
                return items.filter(cur => {
                    return cur.p_name.toString().toLowerCase().includes(search)
                });
            }
        }
    },
    computed: {
        
    },
    mounted() {

        if(window.localStorage.getItem('invoices-month')) {
            this.selectedMonth = window.localStorage.getItem('invoices-month')
        } else {
            this.selectedMonth = new Date().getFullYear() + "-" + (new Date().getMonth()+1)
        }
        this.getInvoices()
        this.invoiceStatusesValues = this.invoiceStatuses.map(cur => {
            return cur.value
        })
    }
}
</script>
<style>
    .invoices {
        padding: 20px;
        width: 100%;
    }
    .invoices .v-datatable .i-right .v-menu__activator {
        justify-content: flex-end;
    }
    .invoices .v-datatable .i-center .v-menu__activator {
        justify-content: center;
    }
</style>
