<template>
    <div class="login">
        <div class="login__wrapper">
            <img src="../assets/img/nyp_logo_color.png" alt="Nyugdíj-Plusz Logo" height="100" class="login__logo">
            <h1 class="login__title"><i class="fas fa-user"></i>Bejelentkezés</h1>
            <div class="form-group">
                <input type="text" placeholder="Felhasználónév" v-model="username">
                <span></span>
            </div>
            <div class="form-group">
                <input type="password" placeholder="Jelszó" v-model="password" @keyup.enter="login">
                <span></span>
            </div>
            <p class="login__error form-error">{{errorMessage}}</p>
            <div class="login__action">
                <button class="cbtn cbtn-pri" @click="login" v-if="!loading">Belépés</button>
                <div class="loader" v-if="loading"></div>
            </div>            
        </div>        
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            username: '',
            password: '',
            errorMessage: '',
            loading: false
        }
    },
    methods: {
        login() {
            this.loading = true;
            this.errorMessage = '';
            axios.post('/auth/local', {
                identifier: this.username,
                password: this.password
            })
            .then(res => {
                this.$store.dispatch('login', {jwt: res.data.jwt, user: res.data.user});
                this.username = '';
                this.password = '';
                this.loading = false;
                this.$router.push({path: '/tagok'});
                this.$store.dispatch('showSnackBar',{text: 'Szia ' + res.data.user.username + '!', color: 'success'});
            })
            .catch(error => {
                console.log(error);
                this.errorMessage = error.response.data.message;
                this.loading = false;
            })
                
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgb(241, 241, 241);
    &__wrapper {   
        position: absolute;
        background-color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 10px;
        box-shadow: 2px 3px 28px -9px rgba(0,0,0,0.85);
        padding: 30px;
        min-width: 400px;
    }
    &__title {
        text-align: center;
        text-transform: uppercase;
        font-size: 26px;
        margin: 15px 0 30px 0;
    }
    &__logo {
        display: block;
        margin: auto;
    }
    .form-group {
        margin-bottom: 20px;
    }
    &__error {
        height: 20px;
        margin-bottom: 20px;
    }
    &__action {
        text-align: center;
        height: 40px;
    }
    .fa-user {
        margin-right: 10px;
    }

}
</style>

