<template>
  <div id="app">
    <v-app theme>
      <top-bar v-if="isAuthenticated"></top-bar>
      <div class="main-content">
        <left-menu v-if="isAuthenticated"></left-menu>
        <router-view></router-view>
      </div>
      <v-snackbar v-model="showSnackBar.show" :bottom="true" :color="showSnackBar.color">{{showSnackBar.text}}<v-btn
        dark
        flat
        @click="hideSnackBar"
      >
        Bezár
      </v-btn></v-snackbar>       
    </v-app>
  </div>  
</template>

<script>
import TopBar from './components/TopBar.vue';
import LeftMenu from './components/LeftMenu.vue';

export default {
  name: 'app',
  components: {
    TopBar,
    LeftMenu
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.user.isAuthenticated;
    },
    showSnackBar: {
      set() {
        this.$store.commit('hide');
      },
      get() {
        return this.$store.state.snackbar;
      }
    }
  },
  methods: {
    hideSnackBar() {
      this.$store.commit('hide');
    }
  },
  mounted() {
    if(window.localStorage.getItem('jwt')) {
      this.$store.dispatch('login', {jwt: window.localStorage.getItem('jwt'), user: JSON.parse(window.localStorage.getItem('user'))})
    }
  }

}
</script>

<style lang="scss" scoped>
  v-app {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .main-content {
    display: flex;
    height: 100%;
    flex-grow: 1;
  }
</style>

