<template>
    <div class="top-bar">
        <img src="../assets/img/nyplusz_logo_full_white.svg" alt="Nyugdíj-Plusz Logó" height="60">
        <div class="user">
            <span class="user__name"><i class="fas fa-user"></i>{{user.username}}</span>
            <button class="cbtn cbtn-sec" @click="logout">Kilépés</button>
        </div>        
    </div>
</template>
<script>
import axios from 'axios';
export default {
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout', {});
            axios.defaults.headers.common['Authorization'] = '';
            this.$router.push({path: '/'});
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../assets/sass/utils/variables';
    .top-bar {
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 0 50px;
        align-items: center;
        background-color: $priColor;

        .user {
            &__name {
                color: #fff;
                font-weight: 600;
                margin-right: 20px;
                .svg-inline--fa {
                    margin-right: 10px;
                }
            }
        }
    }
</style>

