<template>
    <div class="hours">
        <v-layout wrap justify-space-between>
            <v-flex xs12 sm3>
                <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    :return-value.sync="selectedMonth"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                >
                    <v-text-field
                    slot="activator"
                    v-model="selectedMonth"
                    label="Hónap választó"
                    readonly
                    ></v-text-field>
                    <v-date-picker
                    v-model="selectedMonth"
                    type="month"
                    no-title
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="menu = false">Mégse</v-btn>
                    <v-btn flat color="primary" @click="$refs.menu.save(selectedMonth); getHours();">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-flex xs12 sm3>
                <v-text-field
                    label="Kereső"
                    single-line
                    outline
                    v-model="searchText"
                ></v-text-field>
            </v-flex>
        </v-layout>        
        <v-toolbar flat color="white">
        <v-toolbar-title><i class="fas fa-clipboard-list"></i>Havi jelenléti ívek</v-toolbar-title>
        <v-divider
            class="mx-2"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :items="workingHours"
            :loading="loading"
            :rows-per-page-text="'Jelenléti/Oldal'" 
            :rows-per-page-items="rowsPerPage"       
            :search="searchText"   
            class="elevation-1"
        >
            <v-progress-linear slot="progress" color="#faa540" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.partner }}</td>
            <td class="text-xs-left">{{ props.item.member }}</td>
            <td class="text-xs-center">{{ props.item.job_title }}</td>
            <td class="text-xs-center">
                <v-edit-dialog
                    :return-value.sync="props.item.hours.h_hours"
                    large
                    lazy
                    persistent
                    @save="saveHour(props.item)"
                >
                    <div>{{ props.item.hours.h_hours }}</div>
                    <div slot="input" class="mt-3 title">{{ props.item.hours._id ? 'Módosít' : 'Kitölt'}}</div>
                    <v-text-field
                    slot="input"
                    v-model="props.item.hours.h_hours"
                    label="Órák száma"
                    autofocus
                    ></v-text-field>
                </v-edit-dialog>
            </td>
            <td class="text-xs-right">{{ props.item.hours.updatedAt }}</td>
            </template>
            <template slot="no-data">                
                <div style="text-align: center;">Nincs megjelenítendő adat.</div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data () {
      return {
          menu: false,
          selectedMonth: '',
          workingHours: [],
          loading: false,
          rowsPerPage: [10,25,50,{"text":"Mind","value":-1}],
          searchText: '',
          headers: [
              { text: 'Mukáltató', value: 'partner', width: '300px', align: 'left' },
              { text: 'Dolgozó', value: 'member', width: '300px', align: 'left' },
              { text: 'Munka', value: 'job_title', width: '300px', align: 'center' },
              { text: 'Ledolgozott órák', value: 'hours', width: '300px', align: 'center' },
              { text: 'Módosítva', value: 'hours_updated', width: '300px', align: 'right' },
          ]
      }
    },
    methods: {
        getHours() {
            window.localStorage.setItem('hours-month', this.selectedMonth)
            axios.get('/list-hours?date=' + this.selectedMonth)
            .then(res => {
                this.workingHours = res.data
            })
            .catch(error => {
                console.log(error);
            })
        },
        saveHour(item) {
            if(item.hours._id) { // Update
                axios.put('/hours/' + item.hours._id,{
                    h_period: this.selectedMonth,
                    h_hours: parseFloat(item.hours.h_hours),
                    h_contract: item._id
                })
                .then(() => {
                    this.getHours();
                    this.$store.dispatch('showSnackBar',{text: 'Munkaóra módosítva!', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Módosítás sikertelen!', color: 'error'});
                })
            } else { // Create
                axios.post('/hours',{
                    h_period: this.selectedMonth,
                    h_hours: parseFloat(item.hours.h_hours),
                    h_contract: item._id,
                    h_member: item.member,
                    h_partner: item.partner,
                    h_job_title: item.job_title
                })
                .then(() => {
                    this.getHours();
                    this.$store.dispatch('showSnackBar',{text: 'Munkaóra kitöltve!', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Kitöltés sikertelen!', color: 'error'});
                })
            }
        }
    },
    mounted() {
        if(window.localStorage.getItem('hours-month')) {
            this.selectedMonth = window.localStorage.getItem('hours-month')
        } else {
            this.selectedMonth = new Date().getFullYear() + "-" + (new Date().getMonth()+1)
        }
        this.getHours()
    }
}
</script>
<style>
    .hours {
        padding: 20px;
        width: 100%;
    }
    .hours .v-menu__activator {
        justify-content: center;
    }
</style>

