<template>
    <div class="members">
        <v-text-field
            label="Kereső"
            single-line
            outline
            v-model="searchText"
        ></v-text-field>
        <v-toolbar flat color="white">
        <v-toolbar-title><i class="fas fa-users"></i>Szövetkezeti tagok</v-toolbar-title>
        <v-divider
            class="mx-2"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px" attach="#app">
            <v-btn slot="activator" color="primary" dark class="mb-2">Új tag</v-btn>
            <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12>
                    <v-text-field v-model="editedItem.m_tax_id" label="Adóazonosító"></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                    <v-text-field v-model="editedItem.m_name" label="Név"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                    <v-select v-model="editedItem.m_payment_type" :items="paymentTypes" label="Kifizetés módja"></v-select>
                    </v-flex>
                    <v-flex xs12 sm6>
                    <v-select v-model="editedItem.m_voucher" :items="voucherTypes" label="Erzsébet Kártya/Utalvány"></v-select>
                    </v-flex>
                </v-layout>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="close">Bezár</v-btn>
                <v-btn color="blue darken-1" flat @click.native="save">Mentés</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :items="members"
            :loading="loading"
            :rows-per-page-text="'Tag/Oldal'" 
            :rows-per-page-items="rowsPerPage"       
            :search="searchText"   
            class="elevation-1"
        >
            <v-progress-linear slot="progress" color="#faa540" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
            <td>{{ props.item.m_tax_id }}</td>
            <td class="text-xs-left">{{ props.item.m_name }}</td>
            <td class="text-xs-right">{{ props.item.m_payment_type }}</td>
            <td class="text-xs-right">{{ props.item.m_voucher }}</td>
            <td class="text-xs-right">{{ props.item.createdAt | toLocaleString }}</td>
            <td class="text-xs-right">{{ props.item.updatedAt | toLocaleString }}</td>
            <td class="justify-center layout px-0">
                <span class="crud-action" @click="editItem(props.item)"><i class="fas fa-edit"></i>Módosít</span>
                <span class="crud-action" @click="deleteItem(props.item)"><i class="fas fa-trash-alt"></i>Töröl</span>
            </td>
            </template>
            <template slot="no-data">                
                <div style="text-align: center;">Nincs megjelenítendő adat.</div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import axios from 'axios';
export default {

    data () {
      return {
        loading: false,
        dialog: false,
        rowsPerPage: [10,25,50,{"text":"Mind","value":-1}],
        searchText: '',
        paymentTypes: ['Készpénz', 'Utalás','Utalás lakcímre'],
        voucherTypes: ['Nem kér','Kártyára','Utalvány'],
        headers: [
          {
            text: 'Adóazonosító',
            align: 'left',
            sortable: false,
            value: 'm_tax_id'
          },
          { text: 'Név', value: 'm_name', width: '300px', align: 'left' },
          { text: 'Kifizetés módja', value: 'm_payment_type' },
          { text: 'Erzsébet Kártya/Utalvány', value: 'm_voucher' },
          { text: 'Bejegyezve', value: 'createdAt', width: '210px' },
          { text: 'Utoljára módosítva', value: 'updatedAt', width: '210px' },
          { text: 'Szerkesztés', sortable: false}
        ],
        members: [],
        editedIndex: -1,
        editedItem: {
            m_tax_id: '',
            m_name: '',
            m_payment_type: 0,
            m_voucher: 0,
        },
        defaultItem: {
            m_tax_id: '',
            m_name: '',
            m_payment_type: 0,
            m_voucher: 0,
        }
      }
    },
    mounted() {
        this.loading = true;
        axios.get('/members?_limit=10000')
        .then(res => {
            this.members = res.data;
            this.loading = false;
        })
        .catch(error => {
            console.log(error);
            this.loading = false;
        })
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Új Tag' : 'Tag szerkesztése'
        }
    },
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    methods: {
        editItem (item) {
            this.editedIndex = this.members.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            const index = this.members.indexOf(item)
            var answer = confirm('Biztosan törlöd a tagot?');
            if (answer === true) {
                axios.delete('/members/' + this.members[index].id)
                .then(res => {
                    this.members.splice(index, 1);
                    this.$store.dispatch('showSnackBar',{text: 'Tag törölve.', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Tag törlése sikertelen.', color: 'error'});
                })
            }            
        },
        close () {
            this.dialog = false
            setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            }, 300)
        },
        save () {

            // Check Tax ID format
            if(this.editedItem.m_tax_id.length !== 10 || isNaN(this.editedItem.m_tax_id)) {
                this.$store.dispatch('showSnackBar',{text: 'Adóazonosító formátuma nem megfelelő!', color: 'error'});
                return;
            }

            if (this.editedIndex > -1) {
                axios.put('/members/'+this.editedItem.id,{
                    m_tax_id: this.editedItem.m_tax_id,
                    m_name: this.editedItem.m_name,
                    m_payment_type: this.editedItem.m_payment_type,
                    m_voucher: this.editedItem.m_voucher
                })
                .then(res => {
                    Object.assign(this.members[this.editedIndex], res.data);
                    this.$store.dispatch('showSnackBar',{text: 'Tag adatok módosítva.', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Módosítás sikertelen.', color: 'error'});
                })
            } else {
                axios.post('/members',{
                    m_tax_id: this.editedItem.m_tax_id,
                    m_name: this.editedItem.m_name,
                    m_payment_type: this.editedItem.m_payment_type,
                    m_voucher: this.editedItem.m_voucher
                })
                .then(res => {
                    this.members.push(res.data);
                    this.$store.dispatch('showSnackBar',{text: 'Új tag felvéve.', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Új tag felvétele sikertelen.', color: 'error'});
                })
            }            
            this.close()
        }
    }
    
}
</script>
<style lang="scss" scoped>
    .members {
        padding: 20px;
        width: 100%;
    }
</style>
