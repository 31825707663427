<template>
    <div class="left-menu">
        <ul class="navigation">
            <router-link tag="li" to="/tagok" class="navigation__item" active-class="active"><i class="fas fa-users"></i>Tagok</router-link>
            <router-link tag="li" to="/foglalkoztatok" class="navigation__item" active-class="active"><i class="fas fa-user-tie"></i>Foglalkoztató cégek</router-link>
            <router-link tag="li" to="/szerzodesek" class="navigation__item" active-class="active"><i class="fas fa-handshake"></i>Munkaszerződések</router-link>
            <router-link tag="li" to="/jelenleti" class="navigation__item" active-class="active"><i class="fas fa-clipboard-list"></i>Havi jelenléti ívek</router-link>
            <router-link tag="li" to="/szamlazas" class="navigation__item" active-class="active"><i class="fas fa-file-invoice-dollar"></i>Havi számlázáshoz</router-link>
            <router-link tag="li" to="/kifizetes" class="navigation__item" active-class="active"><i class="fas fa-hand-holding-usd"></i>Havi kifizetéshez</router-link>
            <router-link tag="li" to="/jelentesek" class="navigation__item" active-class="active"><i class="far fa-chart-bar"></i>Jelentések</router-link>

        </ul>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    @import '../assets/sass/utils/variables';

    .left-menu {
        background-color: $secColor;
        width: 200px;
        height: 100%;
        flex-shrink: 0;
        padding-top: 20px; 
    }

    .navigation {
        list-style: none;
        padding: 0;
        &__item {
            cursor: pointer;
            position: relative;
            color: rgba(255, 255, 255, 0.4);
            padding: 10px 15px;
            transition: all .2s;
        }
        &__item:hover {
            background-color: rgba(255, 255, 255, 0.05);
        }
        &__item:hover:after {
            content: '';
            display: block;
            position: absolute;
            width: 3px;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $priColor;
        }
        .svg-inline--fa {
            margin-right: 10px;
        }
        &__item.active {
            color: #fff;
        }
        &__item.active:after {
            content: '';
            display: block;
            position: absolute;
            width: 3px;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $priColor;
        }
    }
</style>
