<template>
    <div class="reports">
        <h1><i class="far fa-chart-bar"></i>Jelentések</h1>
        <div class="chart-wrapper">
            <div><canvas id="monthlyIncomeChart" width="400" height="400"></canvas></div>
            <div><canvas id="membersCountChart" width="400" height="400"></canvas></div>
        </div>
        
    </div>
</template>
<script>
import Chart from 'chart.js'
import axios from 'axios'
export default {
    data() {
        return {
            monthlyIncomes: [],
            monthlyIncomesLabels: [],
            membersCount: [],
            membersCountLabels: []
        }
    },
    methods: {
        initMonthlyIncomeChart() {
            var ctx = document.getElementById("monthlyIncomeChart");
            var myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.monthlyIncomesLabels,
                    datasets: [{
                        label: 'Havi szövetkezeti jutalék',
                        data: this.monthlyIncomes,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true,
                                min: 0,
                                max: 1500000
                            }
                        }]
                    }
                }
            });
        },
        initMembersCountChart() {
            var ctx = document.getElementById("membersCountChart");
            var myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.membersCountLabels,
                    datasets: [{
                        label: 'Dolgozó tagok száma',
                        data: this.membersCount,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true,
                                max: 100,
                                min: 0
                            }
                        }]
                    }
                }
            });
        },
        getMonthlyIncomes() {
             axios.get('/reports/monthly-income')
            .then(res => {
                this.monthlyIncomes = res.data.map(cur => {
                    return cur.total
                })
                this.monthlyIncomesLabels = res.data.map(cur => {
                    return new Date(cur._id).toLocaleDateString()
                })
                this.initMonthlyIncomeChart()
            })
            .catch(error => {
                console.log(error);
            })
        },
        getMembersCount() {
            axios.get('/reports/working-members')
            .then(res => {
                this.membersCount = res.data.map(cur => {
                    return cur.members
                })
                this.membersCountLabels = res.data.map(cur => {
                    return new Date(cur._id).toLocaleDateString()
                })
                this.initMembersCountChart()
            })
            .catch(error => {
                console.log(error);
            })
        }
    },
    mounted() {
        this.getMonthlyIncomes();
        this.getMembersCount();
    }
}
</script>
<style lang="scss">
    .reports {
        padding: 20px;

        .fa-chart-bar {
            margin-right: 10px;
        }

        .chart-wrapper {
            display: flex;
        }

    }
</style>
