import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import VueRouter from 'vue-router';
import App from './App.vue'
import {routes} from './routes';
import  { store } from './store/store';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@mdi/font/css/materialdesignicons.css';

import './assets/sass/main.scss';

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.filter('toLocal', function (value) {
  if (!value || value === null) return 0
  return value.toLocaleString('hu-HU')
})


axios.defaults.baseURL = 'https://api.nyugdij-plusz.hu/';

const router = new VueRouter({
  routes,
  mode: 'hash',
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      return savedPosition;
    } 
    if(to.hash){
      return { selector: to.hash};
    }
  }
})

Vue.filter('toLocaleString', value => {
    return new Date(value).toLocaleString();
})
Vue.filter('toLocaleDateString', value => {
  return new Date(value).toLocaleDateString();
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
