const state = {
    show: false,
    text: '',
    color: undefined
};

const getters = {
    text: state => {
        return state.text;
    },
    color: state => {
        return state.color;
    }
};

const mutations = {
    show(state, message){
        state.text = message.text;
        state.color = message.color;
        state.show = true;
    },
    hide(state) {
        state.show = false;
    }
};

const actions = {
    showSnackBar({commit}, message){
        commit('show',{
            text: message.text,
            color: message.color
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
