<template>
    <div class="payments">
        <v-layout wrap justify-space-between>
            <v-flex xs12 sm3>
                <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    :return-value.sync="selectedMonth"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                >
                    <v-text-field
                    slot="activator"
                    v-model="selectedMonth"
                    label="Hónap választó"
                    readonly
                    ></v-text-field>
                    <v-date-picker
                    v-model="selectedMonth"
                    type="month"
                    no-title
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="menu = false">Mégse</v-btn>
                    <v-btn flat color="primary" @click="$refs.menu.save(selectedMonth); getPayments();">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-flex xs12 sm3>
                <v-switch
                v-model="statusFilter"
                :label="'Ki nem fizetett'"
                ></v-switch>
            </v-flex>
            <v-flex xs12 sm3>
                <v-text-field
                    label="Kereső"
                    single-line
                    outline
                    v-model="searchText"
                ></v-text-field>
            </v-flex>
        </v-layout>
        <v-toolbar flat color="white">
        <v-toolbar-title><i class="fas fa-hand-holding-usd"></i>Havi kifizetéshez</v-toolbar-title>
        <v-divider
            class="mx-2"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="payments"
            :loading="loading"
            item-key="_id"
            :rows-per-page-text="'Kifizetés/Oldal'" 
            :rows-per-page-items="rowsPerPage"  
            :search="searchText"         
            class="elevation-1"
            :custom-filter="filterStatus"
        >
            <v-progress-linear slot="progress" color="#faa540" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
                <td class="text-xs-left">{{ props.item.member.m_name }}</td>
                <td class="text-xs-left">{{ props.item.contract.c_job_title }}</td>
                <td class="text-xs-left">{{ props.item.contract.c_wage | toLocal }}</td>
                <td class="text-xs-left">{{ props.item.contract.c_wage_unit }}</td>
                <td class="text-xs-left">{{ props.item.h_hours }}</td>
                <td class="text-xs-left">{{ props.item.grossWage | toLocal }}</td>
                <td class="text-xs-left">
                    <v-edit-dialog
                        :return-value.sync="props.item.payment.pay_food_bill"
                        large
                        lazy
                        persistent
                        @save="saveWage(props.item)"
                    >
                        <div>{{ props.item.payment.pay_food_bill | toLocal }}</div>
                        <div slot="input" class="mt-3 title">Összeg módosítása</div>
                        <v-text-field
                        slot="input"
                        v-model="props.item.payment.pay_food_bill"
                        label="Ételszámla"
                        autofocus
                        ></v-text-field>
                    </v-edit-dialog>
                </td>
                <td class="text-xs-left">{{ props.item.member.m_payment_type }}</td>
                <td class="text-xs-right i-right">
                    <v-edit-dialog
                        :return-value.sync="props.item.payment.pay_status"
                        large
                        lazy
                        persistent
                        @save="saveWage(props.item)"
                    >
                        <div>{{ getStatusText(props.item.payment.pay_status) }}</div>
                        <div slot="input" class="mt-3 title">{{ props.item.member.m_name }} - Kifizetés</div>
                            <v-select
                            slot="input"
                            :items="paymentStatuses"
                            item-text="text"
                            item-value="value"
                            label="Állapot"
                            v-model="props.item.payment.pay_status"
                            ></v-select>
                    </v-edit-dialog>
                </td>
            </template>
            <template slot="no-data">                
                <div style="text-align: center;">Nincs megjelenítendő adat.</div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data () {
        return {
            menu: false,
            selectedMonth: '',
            payments: [],
            loading: false,
            rowsPerPage: [25,50,100,{"text":"Mind","value":-1}],
            paymentStatuses: [
                {text: 'Nincs fizetve', value: 0},
                {text: 'Kifizetve', value: 1},
            ],
            paymentStatusesValues: [],
            headers: [
                { text: 'Munkavállaló', value: 'member.m_name', width: '300px', align: 'left' },
                { text: 'Munka', value: 'contract.c_job_title', width: '300px', align: 'left' },
                { text: 'Órabér', value: 'contract.c_wage', align: 'left' },
                { text: 'Mértékegység', value: 'contract.c_wage', align: 'left' },
                { text: 'Ledolgozott órák', value: 'h_hours', align: 'left' },
                { text: 'Bruttó bér', value: 'grossWage', align: 'left' },
                { text: 'Ételszámla', value: 'payment.pay_food_bill', align: 'left' },
                { text: 'Fizetés módja', value: 'member.m_payment_type', align: 'left' },
                { text: 'Kifizetés állapota', value: 'payment.pay_status', align: 'center' },
            ],
            statusFilter: false,
            searchText: ''
        }
    },
    methods: {
        getPayments() {
            window.localStorage.setItem('payments-month', this.selectedMonth)
            axios.get('/list-payments?date=' + this.selectedMonth)
            .then(res => {
                this.payments = res.data
                this.payments.map(cur => {
                    if(!cur.payment) {
                        cur.payment = { pay_status: 0}
                    }
                    return cur
                })
            })
            .catch(error => {
                console.log(error);
            })
        },
        getStatusText(value) {
            var i = this.paymentStatusesValues.indexOf(value);
            if(i>=0) {
                return this.paymentStatuses[i].text
            } else {
                return 'Nincs fizetve'
            }
        },
        saveWage(item) {
            if(item.payment._id) { // Update
                axios.put('/payments/' + item.payment._id,{
                    pay_status: item.payment.pay_status,
                    pay_food_bill: item.payment.pay_food_bill,
                })
                .then(res => {
                    this.getPayments();
                    this.$store.dispatch('showSnackBar',{text: 'Kifizetés módosítva!', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Módosítás sikertelen!', color: 'error'});
                })
            } else { // Create
                axios.post('/payments',{
                    pay_status: item.payment.pay_status ? item.payment.pay_status : 0,
                    pay_food_bill: item.payment.pay_food_bill,
                    pay_date: item.h_period,
                    pay_contract: item.contract._id
                })
                .then(res => {
                    this.getPayments();
                    this.$store.dispatch('showSnackBar',{text: 'Kifizetés mentve!', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Számla mentés sikertelen!', color: 'error'});
                })
            }
        },
        filterStatus(items, search, filter) {
            if(this.statusFilter) {
                return items.filter(cur => {
                    return cur.payment.pay_status !== 1 && cur.h_member.toString().toLowerCase().includes(search);
                })
            } else {
                return items.filter(cur => {
                    return cur.h_member.toString().toLowerCase().includes(search);
                })
            }
        }
    },
    mounted() {
        if(window.localStorage.getItem('payments-month')) {
            this.selectedMonth = window.localStorage.getItem('payments-month')
        } else {
            this.selectedMonth = new Date().getFullYear() + "-" + (new Date().getMonth()+1)
        }
        this.paymentStatusesValues = this.paymentStatuses.map(cur => {
            return cur.value
        })
        this.getPayments()
    }
}
</script>
<style>
    .payments {
        padding: 20px;
        width: 100%;
    }
</style>
