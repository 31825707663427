import Login from './components/Login';
import Members from './components/views/Members';
import Partners from './components/views/Partners';
import Contracts from './components/views/Contracts';
import Hours from './components/views/Hours';
import Invoices from './components/views/Invoices';
import Payments from './components/views/Payments';
import Reports from './components/views/Reports';

export const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/tagok',
        component: Members
    },
    {
        path: '/foglalkoztatok',
        component: Partners
    },
    {
        path: '/szerzodesek',
        component: Contracts
    },
    {
        path: '/jelenleti',
        component: Hours
    },
    {
        path: '/szamlazas',
        component: Invoices
    },
    {
        path: '/kifizetes',
        component: Payments
    },
    {
        path: '/jelentesek',
        component: Reports
    }
];