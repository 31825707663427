/* eslint-disable */
<template>
    <div class="invoice-item">
        <v-layout wrap justify-space-between>
            <v-flex xs12 sm8>
                <v-text-field
                v-model="item.member.m_name"
                label="Dolgozó"
                readonly
                ></v-text-field>
            </v-flex>
            <v-flex xs12 sm3>
                <v-btn
                :loading="loading"
                color="info"
                @click="recalculate"
                >
                Kalkulál
                <v-icon right dark>fa-sync-alt</v-icon>
                </v-btn>
            </v-flex>
            <v-flex xs12 sm5>
                <v-text-field
                v-model="grossWage"
                label="Bruttó kereset"
                ></v-text-field>
            </v-flex>
            <v-flex xs12 sm5>
                <v-text-field
                v-model="serviceFee"
                label="Szövetkezeti jutalék"
                ></v-text-field>
            </v-flex>
        </v-layout>
        <v-layout justify-end>
            <v-btn color="info" @click="closeDialog">Bezár</v-btn>
            <v-btn color="success" @click="saveItem">Mentés</v-btn>
        </v-layout>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['item'],
    data() {
        return {
            loader: null,
            loading: false,
            grossWage: this.item.grossWage,
            serviceFee: this.item.serviceFee
        }
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      }
    },
    methods: {
        closeDialog() {
            this.$emit('closed',null)
        },
        saveItem() {
            if(this.item.calculated) {
                axios.post('/invoiceitems',{
                    ii_period: this.item.h_period,
                    contracts: this.item.contract._id,
                    ii_gross_wage: this.grossWage,
                    ii_service_fee: this.serviceFee
                })
                .then(res => {
                    this.$emit('update',null)
                    this.$emit('closed',null)
                    this.$store.dispatch('showSnackBar',{text: 'Mentés sikeres!', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Mentés sikertelen!', color: 'error'});
                })
            } else {
                axios.put('/invoiceitems/' + this.item.invoiceitem._id,{
                    ii_gross_wage: this.grossWage,
                    ii_service_fee: this.serviceFee
                })
                .then(res => {
                    this.$emit('update',null)
                    this.$emit('closed',null)
                    this.$store.dispatch('showSnackBar',{text: 'Módosítva!', color: 'success'});
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('showSnackBar',{text: 'Módosítás sikertelen!', color: 'error'});
                })
            }
        },
        recalculate() {
            this.loading = true
            axios.post('/invoiceitems/recalculate',{
                contract: this.item.contract,
                partner: this.item.partner,
                h_hours: this.item.h_hours
            })
            .then(res => {
                this.$store.dispatch('showSnackBar',{text: 'Újra kalkulálva!', color: 'success'});
                this.loading = false
                this.grossWage = res.data.grossWage
                this.serviceFee = res.data.serviceFee
            })
            .catch(error => {
                console.log(error);
                this.$store.dispatch('showSnackBar',{text: 'Kalkulálás sikertelen!', color: 'error'});
                this.loading = false
            })
        }
    }
}
</script>
<style>
    .invoice-item {
        padding: 20px;
    }
</style>
